/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-admin .wm-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #efefef;
}
